import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Main } from "./components/Main";
import { About } from "./components/About";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
   },
   {
        path: '/main',
        element: <Main />
   },
    {
        path: '/about',
        element: <About />
    },
  {
    path: '/fetch-data',
    element: <FetchData />
    }

];

export default AppRoutes;

import React, { Component } from 'react';
import './ChartGallery.css'; // Import the CSS file


export class Main extends Component {
    constructor(props) {
        super(props);
    
    }


    
   

    render() {
        const chartData = require('./imageList.json');
              

        var tableStyle = {
            "border": "1px solid black"
            
            
        };

       

    


        return (

           

            <div>
                

                Important Notice: Please be aware that the data offered here is historical and has not been updated to reflect the latest information. Additionally, the demand and supply estimates are subject to a lag of 4 bars, attributed to the averaging process. To address this, a neural network has been employed to eliminate the lag, enhancing the accuracy and responsiveness of the data. Users are encouraged to consider these factors when interpreting the information and making any decisions based on the provided data.
                <p>The video below will give instructions on how to interpret the charts:</p>

                
                


                <img />


                <h1>Charts</h1>
                <table width='100%'>
                    <thead>
                        <tr style={tableStyle}>
                            <th>Chart Name</th>
                            <th>Chart Image</th>
                        </tr>
                    </thead>
                    <tbody>
                    {chartData.charts.map((chart, index) => (
                        <tr style={tableStyle} key={index}>
                            <td >{chart.name} Chart</td>
                            <td>
                                <img
                                    src={`${process.env.PUBLIC_URL}/charts/${chart.img}?${chartData.cache}`}
                                    alt={`/${process.env.PUBLIC_URL}/charts/${chart.img}`}
                                    style={{ maxWidth: '90%', height: 'auto' }}
                                />

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            
        );
    }
 };

export default Main;

// ReleaseFormModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';

const ReleaseFormModal = ({ isOpen, onClose }) => {
    const [accepted, setAccepted] = useState(false);

    const handleAccept = () => {
        // You can perform any necessary actions upon acceptance here
        setAccepted(true);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Release Form Modal"
        >
            <div>
                <h2>Release Form</h2>
                <p><strong>Disclaimer for "Optick Analytica" for informational purposes:</strong></p>
                    <p>The content provided <strong>should not be interpreted as a solicitation or recommendation</strong>. This material is solely intended for <strong>informational purposes</strong> and does not take into consideration the specific investment objectives, financial situations, or means of individual users. <strong>Optick Analytica is not seeking any actions to be taken</strong> based on this information. This material should not be viewed as a <strong>recommendation</strong>, an <strong>offer to buy or sell</strong>, or a <strong>solicitation to buy or sell any securities, financial products, or instruments, or to engage in any particular trading strategy</strong> in any jurisdiction where such actions may be considered <strong>illegal</strong>.</p>
                    <p>Certain transactions, including those involving <strong>futures, options, and high-yield securities, carry significant risks</strong> and may not be suitable for all investors. While this material is based on information that <strong>Optick Analytica deems reliable</strong> and strives to keep current, we have not independently verified this information and cannot guarantee its <strong>accuracy, currency, or completeness</strong>. Therefore, it should not be relied upon as such.</p>
                    <p><strong>Information Made Available Through this Service:</strong></p>
                    <p>You are authorized to <strong>store, display, analyze, modify, reformat, and print the information</strong> accessible through these services for your <strong>personal use only</strong>. <strong>Republishing, transmitting, or reproducing this information, either in whole or in part, in any format for third parties</strong>, requires the express written consent of <strong>Optick Analytica</strong>. Additionally, you are not allowed to <strong>alter, obscure, or remove any copyright, trademark, or other notices</strong> provided to you in connection with this information. <strong>Optick Analytica reserves the right, at our discretion, to add, modify, or remove any information</strong> as it deems necessary for editorial or business reasons. These <strong>Terms and Conditions of Use do not transfer or grant any rights to the information</strong> beyond those specifically described herein. All rights not explicitly granted herein are retained by <strong>Optick Analytica</strong> or the third-party providers from whom we have sourced the information.</p>
                    <p>Users are expected to <strong>review and adhere to any additional terms and conditions that may be posted on this service periodically</strong> concerning information obtained from specific third-party providers. These <strong>third-party providers shall not be liable for monetary damages</strong> resulting from the information provided through this service.</p>
                <button onClick={handleAccept}>Accept</button>
            </div>
        </Modal>
    );
};

export default ReleaseFormModal;

import React, { Component } from 'react';

export class About extends Component {
    static displayName = About.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  render() {
    return (
      <div>
            <h2>Illuminating Data Insights Inspired by Newton</h2>
            <p>At <em>Opticks Analytica</em>, our name is more than just a nod to history; it's a reflection of our commitment to revealing the hidden truths within data. Named after Sir Isaac Newton's seminal work, "<strong>Opticks</strong>," we draw inspiration from the profound insights Newton unlocked about the nature of light. In the same way Newton used prisms to disentangle the complexities of light, we employ cutting-edge analytics to split data into its fundamental components.</p>
            <p>Just as a prism reveals the true properties of light, we dissect complex datasets to gain a deeper understanding of the inherent characteristics of the market.</p>
          



      </div>
    );
  }
}
